






import Vue from 'vue'
import SecondaryUsersPage from './SecondaryUsers.vue'
export default Vue.extend({
    name: 'SecondaryUserIndex',
    components: {
        SecondaryUsersPage
    },
})
